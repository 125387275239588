import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../components/Home";
import Logon from "../components/Logon";
import Login from "../components/Login";
import LogonFromGuest from "../components/LogonFromGuest";
import Logout from "../components/Logout";
import Record from "../components/Record";
import GraphCalendar from "../components/GraphCalendar";
import GraphChapter from "../components/GraphChapter";
import Memory from "../components/Memory";
import Setting from "../components/Setting";
import EmailCheck from "../components/EmailCheck";
import GoogleRedirect from "../components/GoogleRedirect";
import LINERedirect from "../components/LINERedirect";

export const RouterConfig = () => {
  return (
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/logon_from_guest" element={<LogonFromGuest />} />
        <Route path="/logon" element={<Logon />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/record" element={<Record />} />
        <Route path="/graph/calendar" element={<GraphCalendar />} />
        <Route path="/graph/chapter" element={<GraphChapter />} />
        <Route path="/memory" element={<Memory />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/email/check" element={<EmailCheck />} />
        <Route path="/google/redirect" element={<GoogleRedirect />} />
        <Route path="/line/redirect" element={<LINERedirect />} />
      </Routes>
    </React.StrictMode>
  );
};