import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavDropdown, Toast, ToastContainer, Offcanvas } from 'react-bootstrap';
import getServerUrl from '../functions/getServerUrl';
import getDateString from '../functions/getDateString';

export default function Header() {
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [guest, setGuest] = useState(null);
  const [showCountToast, setShowCountToast] = useState(false);
  const [readingRecordCount, setReadingRecordCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const checkAccount = async () => {
    const registerAsGuestUser = async () => {
      try {
        const res = await fetch(getServerUrl() + "Users/register_as_guest", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await res.json();
        console.log(data);

        return data;
      }
      catch (e) {
        console.error(e);
      }
    }

    const _userId = localStorage.getItem("userId");
    const _sessionId = localStorage.getItem("sessionId");
    const _guest = localStorage.getItem("guest")

    // ローカルストレージにユーザ情報がなければゲストユーザーとして登録する
    if (_userId === null || _sessionId === null) {
      const data = await registerAsGuestUser();

      localStorage.setItem("userId", data.userId);
      localStorage.setItem("sessionId", data.sessionId);
      localStorage.setItem("guest", true);

      window.location.reload();
    }
    else {
      setUserId(_userId);
      setSessionId(_sessionId);
      setGuest(_guest);
    }
  }

  const getUserInfo = async () => {
    const res = await fetch(getServerUrl() + "Users/me?userId=" + userId + "&sessionId=" + sessionId);

    if (res.status === 401) {
      // 認証が失敗したらユーザーデータは削除する
      localStorage.removeItem("userId");
      localStorage.removeItem("sessionId");

      window.location.href = "/login";
    }

    const data = await res.json();
    const user = data.user;

    setUserName(user.name);
  }

  const getLogsCount = async () => {
    const date = new Date();
    const dateString = getDateString(date);
    const res = await fetch(getServerUrl() + "Users/day_summary?userId=" + userId + "&sessionId=" + sessionId + "&date=" + dateString);
    const data = await res.json();
    setReadingRecordCount(data.readingRecordCount);
    setUserCount(data.userCount);

    const obtainedDate = localStorage.getItem("obtainedDate");
    if (data.readingRecordCount > 0 && obtainedDate !== dateString) {
      setShowCountToast(true);
    }
    localStorage.setItem("obtainedDate", dateString);
  }
  useEffect(() => {
    checkAccount();
  }, []);

  useEffect(() => {
    if (userId !== null) {
      getUserInfo();
      getLogsCount();
    }
  }, [userId]);

  return (
    <>
      <ToastContainer
        className="p-3"
        position="top-end"
        style={{ zIndex: 1 }}>
        <Toast onClose={() => setShowCountToast(false)} show={showCountToast} delay={3000} autohide>
          <Toast.Header closeButton={false}>
            <strong className="me-auto">全利用者の統計</strong>
          </Toast.Header>
          <Toast.Body>
            今日の記録数：{readingRecordCount}件<br />
            今日の利用者数：{userCount}人
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Navbar bg="success" variant="dark" expand="false">
        <Container fluid>
          <Navbar.Brand href="/">通読くん</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Offcanvas
            id="basic-navbar-nav"
            aria-labelledby="basic-navbar-nav"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="basic-navbar-nav">
                通読くん
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/">ホーム</Nav.Link>
                <Nav.Link href="/record">検索</Nav.Link>
                <Nav.Link href="/graph/calendar">年間カレンダー</Nav.Link>
                <Nav.Link href="/graph/chapter">章一覧</Nav.Link>
                <Nav.Link href="/memory">覚える聖句</Nav.Link>
                <Nav.Link href="https://www.tsudokukun.jp/webapp" target="_blank" rel="noopener noreferrer">このサイトについて</Nav.Link>
                <NavDropdown title="全利用者の統計">
                  <NavDropdown.Item>
                    今日の記録数：{readingRecordCount}件<br />
                    今日の利用者数：{userCount}人
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/setting">設定</Nav.Link>
                {userId !== null &&
                  <>
                    {guest !== null ?
                      <Navbar.Text>
                        {userName}
                      </Navbar.Text>
                      :
                      <NavDropdown title={userName}>
                        <NavDropdown.Item href="/logout">ログアウト</NavDropdown.Item>
                      </NavDropdown>
                    }
                  </>
                }
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
