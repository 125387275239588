import React from 'react';
import { RouterConfig } from './route/Route';

function App() {
  return (
    <React.StrictMode>
      <RouterConfig />
    </React.StrictMode>
  );
}

export default App;
